





























import GithubReportIssueBanner from "@/components/GithubReportIssueBanner.vue";
import allTechDataDescriptions from "@/tech/AllTechDataDescriptions";
import { getTechMetadata, TechId } from "@/tech/AllTechMetadata";
import { verifyVariantValue } from "@/tech/TechMetadata";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    GithubReportIssueBanner,
    ...allTechDataDescriptions,
  },
})
export default class extends Vue {
  public data() {
    const { techId } = this.$route.params;
    const { jumpDistance, aerialType, facing, fall, hop } = this.$route.query;

    const techData = getTechMetadata(techId);
    if (techData === null) {
      return {
        component: null,
        badVariantName: null,
      };
    } else {
      const techMetadata = techData.metadata;
      if (!verifyVariantValue(techMetadata, "jumpDistance", jumpDistance)) {
        return {
          component: null,
          badVariantName: "jumpDistance",
        };
      } else if (!verifyVariantValue(techMetadata, "aerialType", aerialType)) {
        return {
          component: null,
          badVariantName: "aerialType",
        };
      } else if (!verifyVariantValue(techMetadata, "facing", facing)) {
        return {
          component: null,
          badVariantName: "facing",
        };
      } else if (!verifyVariantValue(techMetadata, "fall", fall)) {
        return {
          component: null,
          badVariantName: "fall",
        };
      } else if (!verifyVariantValue(techMetadata, "hop", hop)) {
        return {
          component: null,
          badVariantName: "hop",
        };
      } else {
        return {
          component: allTechDataDescriptions[techData.techId],
          badVariantName: null,
          techMetadata,
          variant: {
            jumpDistance,
            aerialType,
            facing,
            fall,
            hop,
          },
        };
      }
    }
  }
}
