







































































import allTechData from "@/tech/AllTechMetadata";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import BaseTechComponent from "./base/BaseTechComponent";
import JumpDistanceVariantMixin from "./base/JumpDistanceVariantMixin";

@Component
export default class extends mixins(
  BaseTechComponent,
  JumpDistanceVariantMixin,
) {
  get controlInputs(): string {
    return `${this.jumpInputs} - d`;
  }
}
