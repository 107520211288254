






















































































































import ControlInputs from "@/components/ControlInputs.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: { ControlInputs },
})
export default class extends Vue {}
