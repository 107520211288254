
















import { REPO_GITHUB_ISSUES_URL } from "@/constants";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class extends Vue {
  get issueUrl(): string {
    const url = new URL(REPO_GITHUB_ISSUES_URL);
    url.searchParams.set(
      "body",
      `Page: ${window.location.toString()}

<!-- fill in your issue body here -->`,
    );
    return url.toString();
  }
}
