










































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop({ type: String, required: true })
  public videoId!: string;

  @Prop({ type: String, required: false })
  public startTime: string | undefined;

  get src(): string {
    // I tried to use `https://youtube-nocookie.com` -- which is apparently the
    // legitimate "privacy-enhanced" mode in the embed widget on YouTube -- but
    // it failed with an HSTS error. Maybe one day!
    const baseUrl = "https://youtube.com";

    if (this.startTime !== undefined) {
      const [minutes, seconds] = this.startTime.split(":");
      const startSeconds = 60 * parseInt(minutes, 10) + parseInt(seconds, 10);
      return `${baseUrl}/embed/${this.videoId}?start=${startSeconds}`;
    } else {
      return `${baseUrl}/embed/${this.videoId}`;
    }
  }
}
