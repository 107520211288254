
































































































import allTechData from "@/tech/AllTechMetadata";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import BaseTechComponent from "./base/BaseTechComponent";
import JumpDistanceVariantMixin from "./base/JumpDistanceVariantMixin";

@Component
export default class extends mixins(
  BaseTechComponent,
  JumpDistanceVariantMixin,
) {
  get controlInputs(): string {
    return `${this.jumpInputs} - d . ${this.aerialInputs}`;
  }

  get aerialInputs(): string {
    switch (this.variant.aerialType) {
      case "nair":
        return "a";
      case "fair":
        return "a + r";
      case "bair":
        return "a + l";
      case "uair":
        return "a + u";
      case "dair":
        return "a + d";
    }
  }
}
