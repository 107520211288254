





















































import allTechData from "@/tech/AllTechMetadata";
import Vue from "vue";
import Component from "vue-class-component";
import BaseTechComponent from "./base/BaseTechComponent";

@Component
export default class extends BaseTechComponent {}
